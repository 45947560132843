import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home.vue'
import Events from '../components/Events.vue'
import Newcomers from '../components/Newcomers.vue'
import MeetingList from '../components/MeetingList.vue'
import Contribute from '../components/Contribute.vue'
import Resources from '../components/Resources.vue'
// import Register from '../components/Register.vue'
import TwentyQs from '../components/TwentyQs.vue'
// import NotFound from '../components/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/events',
    name: 'Events',
    component: Events
  },
  {
    path: '/newcomers',
    name: 'Newcomers',
    component: Newcomers
  },
  {
    path: '/meeting_list',
    name: 'MeetingList',
    component: MeetingList
  },
  {
    path: '/contribute',
    name: 'Contribute',
    component: Contribute
  },
  {
    path: '/resources',
    name: 'Resources',
    component: Resources
  },
  // {
  //   path: '/register',
  //   name: 'Register',
  //   component: Register
  // },
  {
    path: '/twentyQs',
    name: 'TwentyQs',
    component: TwentyQs
  },
  {
    path: '/:pathMatch(.*)*',
    component: Home
}

]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
