<template>

  <div class="topdiv" />
  <div>

    <div class="hamburger-menu">
      <input id="menu__toggle" type="checkbox" />
      <label class="menu__btn" for="menu__toggle">
        <span> </span>
      </label>

      <div class="menu__box sidenav">
        <img v-bind:src="'logo_sa2v2.svg'" alt="SA Logo" />
        <!-- <h2 class="center">CASA<br>Intergroup</h2> -->
        <hr>
        <router-link to="/" class="button-46" role="button" @click='hideMenu'>HOME</router-link><br />
        <hr>
        <router-link to="/newcomers" class="button-46" role="button" @click='hideMenu'>NEWCOMERS</router-link><br />
        <hr>
        <router-link to="/meeting_list" class="button-46" role="button" @click='hideMenu'>MEETING LIST</router-link><br />
        <hr>
        <router-link to="/events" class="button-46" role="button" @click='hideMenu'>EVENTS</router-link><br />
        <hr>
        <router-link to="/contribute" class="button-46" role="button" @click='hideMenu'>CONTRIBUTE</router-link><br />
        <hr>
        <router-link to="/resources" class="button-46" role="button" @click='hideMenu'>RESOURCES</router-link><br />
        <hr />
      </div>

      <div class="main">
        <div class="box box1">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',

  methods: {
    hideMenu() {
      let menuOpen = document.querySelector('#menu__toggle').checked;

      if (menuOpen == true) {
        document.querySelector('#menu__toggle').checked = false
      }
    },
  }
};

</script>

<style>
#app {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  margin-top: 20px;
}

.box1 {
  background-color: #fff;
  color: #000;
  opacity: .85;
  border-radius: .5em;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 15px;
  padding-right: 15px;
  min-width: fit-content;
}

.center {
  text-align: center;
}

.button-46 {
  align-items: center;
  /* background-color: rgba(240, 240, 240, 0.26); */
  /* border: 1px solid #dfdfdf; */
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-flex;
  font-family: Inter, sans-serif;
  font-size: 16px;
  justify-content: center;
  line-height: 28px;
  min-width: 100%;
  padding: 14px 54px;
  text-decoration: none;
  transition: all 0.2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-46:active,
.button-46:hover {
  outline: 0;
}

.button-46:hover {
  background-color: #b6b6b6;
  border-color: rgba(0, 0, 0, 0.19);
}

input[type=text],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
}

#menu__toggle {
  opacity: 0;
  display: none;
}

@media screen and (max-width: 450px) {
  .button-46 {
    font-size: 16px;
    min-width: 225px;
    padding: 14px 16px;
  }

  body {
    background-repeat: no-repeat;
    background-position: center;
    /* background-size: 100vh; */
  }

  .topdiv {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 40px;
    background-color: white;
    z-index: 2;
    position: fixed;
  }

  #menu__toggle {
    opacity: 0;
  }

  #menu__toggle:checked+.menu__btn>span {
    transform: rotate(45deg);
  }

  #menu__toggle:checked+.menu__btn>span::before {
    top: 0;
    transform: rotate(0deg);
  }

  #menu__toggle:checked+.menu__btn>span::after {
    top: 0;
    transform: rotate(90deg);
  }

  #menu__toggle:checked~.menu__box {
    left: 0 !important;
  }

  .menu__btn {
    position: fixed;
    top: 20px;
    left: 20px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 2;
  }

  .menu__btn>span,
  .menu__btn>span::before,
  .menu__btn>span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #616161;
    transition-duration: .25s;
  }

  .menu__btn>span::before {
    content: '';
    top: -8px;
  }

  .menu__btn>span::after {
    content: '';
    top: 8px;
  }

  .menu__box {
    display: block;
    position: fixed;
    top: 0;
    left: -100%;
    height: 100%;
    margin: 0;
    padding: 40px 0;
    list-style: none;
    background-color: #ffffff;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
    transition-duration: .25s;
  }

  .menu__item {
    display: block;
    padding: 12px 24px;
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    transition-duration: .25s;
  }

  .menu__item:hover {
    background-color: #CFD8DC;
  }
}
</style>
