<template>
<h1>Resources</h1>
<hr>
<h3>Literature</h3>
Literature can be ordered from our local intergroup for members who are in the DMV area. It is available for pickup or meetup. To place your order please use the link below:
<br>
<a href="https://www.jotform.com/230153063966152">Literature Order Form</a><br>
<br>
<hr>
<br>
<h3>Other Resources</h3>
To gain further information about Sexaholics Anonymous, please visit <a href="https://www.SA.org">www.SA.org</a><br>
<br>
<br>
</template>